














































































































































@use "~@goodless/scss/base/variables.scss" as *;
@use '~@goodless/scss/base/text-styles.scss' as *;

.date-selection-view {
    min-width: 350px;
    user-select: none;

    @media (max-width: 350px) {
        min-width: calc(100vw - 30px);
    }

    > header{
        padding: 10px 10px;
        display: flex;
        flex-direction: row;
        align-items: center;

        > h1 {
            flex-grow: 1;
            text-align: center;
            @extend .style-h4;
        }
    }

    > div {
        padding-bottom: 10px;

        > .days {
            display: flex;
            padding: 0px 10px;
            padding-bottom: 7px;
            margin-bottom: 5px;
            border-bottom: 2px solid $color-border;

            > div {
                flex-grow: 1;
                flex-basis: 0;
                position: relative;
                z-index: 1;
                margin: 0;
                padding: 5px 0;
                text-align: center;
                @extend .style-input;
                font-weight: 600;
                color: $color-gray;
            }
        }

        > .row {
            display: flex;
            padding: 3px 10px;

            > button {
                flex-grow: 1;
                flex-basis: 0;
                position: relative;
                z-index: 1;
                margin: 0;
                padding: 8px 0;

                cursor: pointer;
                touch-action: manipulation;
                user-select: none;

                @extend .style-input;

                &::after {
                    position: absolute;
                    content: '';
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    z-index: -1;
                    border-radius: $border-radius;
                    background: $color-background;
                    transition: background-color 0.2s, transform 0.2s;
                    transform: scale(0.9, 0.9);
                }

                &.selected {
                    color: white;
                    font-weight: bold;

                    &::after {
                        background: $color-primary;
                        transform: scale(1, 1);
                    }
                }

                &:active, &:hover {

                    &::after {
                        background: $color-background-primary;
                        transform: scale(1, 1);
                    }
                }

                &:active {

                    &::after {
                        background: $color-background-gray;
                        transform: scale(1, 1);
                    }
                }

                &.other-month {
                    opacity: 0.3;
                }

                &.disabled {
                    opacity: 0.3;
                    color: red;
                    cursor: not-allowed;

                    &.other-month {
                        opacity: 0.1;
                    }
                }
            }
        }
    }
}
