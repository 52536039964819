




































































































































































@use '~@goodless/scss/base/variables.scss' as *;
@use '~@goodless/scss/base/text-styles.scss' as *;

.webshop-view {
    .product-grid {
        display: grid;
        gap: 15px;
        grid-template-columns: repeat(4, minmax(200px, 1fr));

        @media (max-width: 500px) {
            grid-template-columns: repeat(2, minmax(150px, 1fr));
            gap: 10px;
        }
    }


    .product-box {
        background: $color-background;
        box-shadow: 0px 3.46388px 27.7111px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        border: $border-width solid $color-border;
        transition: border-color 0.2s, transform 0.2s;
        touch-action: manipulation;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        cursor: pointer;

        &.selected {
            border-color: $color-primary;
        }

        &:active {
            transform: scale(0.9, 0.9);
        }

        display: flex;
        flex-direction: column;

        > figure {
            padding-bottom: 100%;
            position: relative;

            @media (max-width: 500px) {
                padding-bottom: 90%;
            }

            span.badge {
                position: absolute;
                right: 10px;
                top: 10px;
                background: $color-primary;
                border-radius: $border-radius;
                padding: 2px 7px;
                color: $color-background;
                display: inline-block;
                @extend .style-h4;
                z-index: 1;
            }

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        > div {
            border-top: $border-width solid $color-border;
            padding: 20px;

            > h1 {
                display: flex;
                flex-direction: row;

                > span:first-child {
                    @extend .style-h3;
                    font-size: 18px;
                    margin-right: auto;

                    @media (max-width: 600px) {
                        font-size: 16px;
                    }

                    @media (max-width: 500px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }

}
