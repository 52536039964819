








































@use '~@goodless/scss/base/variables.scss' as *;
@use '~@goodless/scss/base/text-styles.scss' as *;

.product-container-view {
    position: relative;

    @media (min-width: 900px) {
        > .navigation-bar {
            display: none;
        }
    }

    > main {
        @media (min-width: 900px) {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin: calc(-1 * var(--go-vertical-padding, 40px)) calc(-1 * var(--go-horizontal-padding, 40px));

            > * {
                flex-basis: 50%;
                flex-grow: 1;
                flex-shrink: 0;
                min-width: 0;

            }

            > .left {
                position: sticky;
                top: 0;
                height: calc(var(--vh, 1vh) * 100);

            }

            > .right {
                background: $color-background-shade;
                border-left: $border-width solid $color-border;
            }
        }

        @media (max-width: 901px) {
            > .right > * {
                min-height: 0;

                > .navigation-bar {
                    display: none;
                }
            }
        }
    }
    
}
