




















































































































@use '~@goodless/scss/base/variables.scss' as *;
@use '~@goodless/scss/base/text-styles.scss' as *;

.product-view {
    .submit-button {
        margin-top: 15px;
    }

    .amount-description {
        margin-top: 5px;
    }

    .price-table {

        > div {
            display: flex;
            flex-direction: row;
            padding: 5px 0;
            align-items: center;

            > div:first-child {
                flex-basis: 100px;
                padding-right: 15px;
                @extend .style-h4;
            }

            > div:last-child {
                color: $color-gray;
            }
        }
    }

    > main {

    }

    .product-description {
        white-space: pre-wrap;

        @extend .style-description;
        margin-bottom: 20px;
    }



}
