







































































































@use "~@goodless/scss/base/variables.scss" as *;

.input.date-selection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    touch-action: manipulation;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:active, &:hover {
        background: $color-background-primary;
    }

    > span {
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.icon {
            flex-shrink: 0;
        }
    }
}
