

























































































@use "~@goodless/scss/base/variables.scss" as *;
@use '~@goodless/scss/base/text-styles.scss';

.context-menu-container {
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    &.show-enter-active,
    &.show-leave-active {
        transition: opacity 0.2s;
    }
    &.show-leave-to {
        opacity: 0;
    }
}

.context-menu {
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    background: $color-background;
    padding: 4px 0px;
    border-radius: $border-radius;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: $border-radius;
    box-sizing: border-box;
    min-width: 250px;
    max-width: 100vw;
    max-width: calc(100vw - 30px);
    overflow: hidden;
    --go-horizontal-padding: 15px;
}
