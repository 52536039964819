













@use '~@goodless/scss/base/variables.scss' as *;
@use '~@goodless/scss/base/text-styles.scss' as *;

header.menu {
    display: flex;
    flex-direction: row;
    position: sticky;
    z-index: 100;
    top: 0;
    background: $color-background;
    padding: 15px 15px;
    height: 45px;
    align-items: center;
    border-bottom: 2px solid $color-border;

    .logo {
        height: 36px;
        margin-top: 8px; // correct g alignemnt

        @media (max-width: 400px) {
            height: 24px;
        }
    }

    > .left {
        margin-right: auto;

        display: flex;
        flex-direction: row;
        align-items: center;
        text-decoration: none;

        > span {
            margin-left: 30px;
            
            &:link, &:visited, &:active, &:hover, & {
                font-weight: bold;
                color: $color-gray;
                font-family: Pangram;
                font-size: 16px;
                text-decoration: none;
            }
            
            @media (max-width: 800px) {
                display: none;
            }
        }

    }

    > .right {
        margin-right: -10px;

        > * {
            margin: 0 10px;
        }
    }
}
