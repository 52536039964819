


















































































































@use '~@goodless/scss/base/variables.scss' as *;
@use '~@goodless/scss/base/text-styles.scss' as *;

.general-view {
    .cart-button {
        .badge {
            background: $color-primary;
            border-radius: $border-radius;
            padding: 2px 7px;
            color: $color-background;
            display: inline-block;
            vertical-align: middle;
            @extend .style-h4;
            z-index: 1;
            margin-left: 5px;
        }
        
    }   
}
