






























































@use '~@goodless/scss/base/variables.scss' as *;
@use '~@goodless/scss/base/text-styles.scss' as *;

.product-image-view {
    figure {
        width: 100%;
        height: 100%;
        position: relative;

        @media (max-width: 901px) {
            height: 300px;
        }

        img {
            left: 50%;
            top: 50%;
            width: calc(100% - 30px);
            height: calc(100% - 30px);
            max-height: 60%;
            object-fit: contain;
            position: absolute;
            transform: translate(-50%, -50%);
            user-select: none;
            pointer-events: none;
        }

        .button {
            position: absolute;
            top: 0;
            bottom: 0;
            height: 100%;
            z-index: 2;

            &.arrow-right-small {
                right: 10px;
            }

            &.arrow-left-small {
                left: 10px;
            }
        }
    }
    
}
