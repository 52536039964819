


































































@use '~@goodless/scss/base/variables' as *;
@use '~@goodless/scss/base/text-styles' as *;

.checkout-navigation {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    position: relative;

    > main {
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100);
        box-sizing: border-box;
    }

    > .progress {
        position: fixed;
        left: 0;
        top: 0;
        height: $border-width;
        pointer-events: none;
        background: $color-primary;
        border-top-right-radius: $border-width/2;
        border-bottom-right-radius: $border-width/2;
        width: 0;
        opacity: 1;
        transition: width 0.3s, opacity 0.3s;
        z-index: 101;

        &.hide {
            transition: width 0.3s, opacity 0.2s 0.3s;
            opacity: 0;
        }
    }
}

